@import '@/theme/newVariables.scss';

.signInMobileIconAndLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 6px;
  position: relative;

  @media #{$small-and-down} {
    padding: unset;
    justify-content: space-evenly;
    height: 48px;
  }
  .signInMobileLabel {
    font-size: 12px;
    white-space: nowrap;
    color: $az-black;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    display: block;
  }
}

.fixedSignInMobileIconAndLabel {
  @media #{$small-and-down} {
    min-width: 54px;
  }
}
