@import '../../theme/newVariables';

.headerPencilAd {
  display: flex;
  flex-direction: column;

  p:nth-child(1) {
    display: flex;
    font-family: $font-az-cond-medium;
    text-transform: uppercase;
    font-size: $font-size-14;
    line-height: $font-size-18;
    letter-spacing: 1.25px;
    color: $az-grey-6;
  }

  p:nth-child(2) {
    display: flex;
    font-family: $font-az-helvetica;
    font-size: $font-size-12;
    letter-spacing: 0.3px;
    line-height: 1.33px;
    color: $az-grey-4;
    align-items: baseline;
    padding-right: $font-size-20;
  }

  span:nth-child(1) {
    font-family: $font-az-helvetica-medium;
    line-height: 1.33;
    letter-spacing: 0.2px;
    font-size: $font-size-10;
    text-transform: uppercase;
    margin-left: $az-spacing-5xs;
    font-weight: normal;
  }
}

@media #{$small-and-down} {
  .headerPencilAd {
    margin-left: $font-size-16;
    padding-top: $az-spacing-4xs;
    padding-bottom: $az-spacing-4xs;
    min-width: $az-spacing-300;

    p:nth-child(1) {
      font-weight: 500;
      font-size: $font-size-12;
      letter-spacing: normal;
    }

    p:nth-child(2) {
      font-size: $font-size-10;
    }

    span:nth-child(1) {
      font-weight: normal;
      font-size: $font-size-10;
    }
  }
}

@media #{$medium-and-up} {
  .headerPencilAd {
    display: inline-flex;
    align-items: flex-start;
    border: solid $az-grey-2 $az-spacing-1;
    border-top: none;
    border-bottom: none;
    padding: $az-spacing-zero $az-spacing-44;
    transform: skewX(-30deg);
    height: $az-spacing-45;

    p:nth-child(1),
    p:nth-child(2) {
      transform: skewX(0deg);
    }

    p:nth-child(1) {
      font-weight: 500;
      letter-spacing: 1.3px;
      color: $az-grey-5;
    }

    p:nth-child(2) {
      font-size: $font-size-10;
      letter-spacing: 0.4px;
      font-weight: normal;
    }

    span:nth-child(1) {
      font-weight: 500;
    }
  }

  .headerPencilAd > div {
    display: flow-root;
    margin: auto;
    transform: skewX(30deg);
  }
}
