@import '../../../theme/newVariables';

.darkTheme {
  background-color: $az-black;
  color: $az-white;

  .darkAZBtn {
    color: $az-white;
    padding: $az-spacing-zero;
    font-size: $font-size-14;
  }
}

.lightTheme {
  background-color: $az-white;
  color: $az-black;

  .lightAZBtn {
    color: $az-black;
    padding: $az-spacing-zero;
    font-size: $font-size-14;
  }
}

.shippingTruckLogo {
  width: $az-spacing-30;
  height: $az-spacing-xs;
}

.darkTheme,
.lightTheme,
.dynamicContent,
.ctaLink {
  display: flex;
}

.darkTheme,
.lightTheme {
  box-shadow: $az-box-shadow-gray-1;
  justify-content: center;
  min-height: $az-spacing-38;
  padding: $az-spacing-4xs;
  align-items: center;
}

.dynamicContent {
  flex-flow: row;
  font-family: $font-az-cond-heavy;
  font-size: $font-size-16;
  margin: $az-spacing-zero $az-spacing-s $az-spacing-zero $az-spacing-xs;

  p:first-child {
    margin: $az-spacing-zero;
    line-height: $az-spacing-xxs;
    font-size: $font-size-16;
  }

  p:nth-child(2) {
    line-height: $az-spacing-18;
    font-family: $font-az-helvetica;
    font-size: $font-size-14;
    padding-left: $az-spacing-xxs;
    color: $az-grey-7;
  }
}

@media #{$medium-only} {
  .dynamicContent {
    flex-flow: column;

    p:nth-child(2) {
      padding-left: $az-spacing-zero;
    }
  }
}

@media #{$small-and-down} {
  .dynamicContent {
    flex-flow: column;
    font-size: $font-size-14;
    margin: $az-spacing-zero $az-spacing-4xs $az-spacing-zero $az-spacing-xxxs;

    p:nth-child(2) {
      font-size: $font-size-10;
      padding-left: $az-spacing-zero;
    }
  }
}
